export const HST_MULTIPLIER = 1.13
export const UNRESERVED_FIXED_FEE = 3000
export const FULL_SELLER_FEE_PERCENT = 0.05

export const ONE_MILLION = 1000000

export const SLIDER_OPTIONS = [
  100000, 150000, 250000, 350000, 450000, 550000, 650000, 800000, 900000, 1000000, 1200000, 1400000, 1600000, 1800000,
  2000000,
]

export const SLIDER_STEP = 1
export const SLIDER_MIN = 0
export const SLIDER_MAX = SLIDER_OPTIONS.length - 1
